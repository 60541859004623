<template>
<!-- <v-row class="orange" justify="center"> -->
    <v-card
        :loading="loading"
        class="d-flex flex-column"
        hover
        ref="card"
        height="300"
        width="300"

        @click.stop="selected"
    >
        <template slot="progress">
            <v-progress-linear
                color="deep-purple"
                height="10"
                indeterminate
            ></v-progress-linear>
        </template>
        <div class="d-flex flex-row justify-center pa-2 mt-4" style="height:40%;">
            <img :src="imgSource" class="farm-logo"/>
        </div>
        <v-card-title primary ref="title" class="mt-4">
            <span :class="titleClass">{{ title }}</span>
        </v-card-title>
        <v-card-subtitle v-if="count !== -1">{{ count }} Punti Di Vendita</v-card-subtitle>
    </v-card>

<!-- </v-row> -->
</template>

<script>
import { mapActions } from "vuex";

import * as snackbarAction from "../../store/actions/snackbar";

export default {
    props: {
        imgSource: {
            type: String,
            required: false,
            default: "https://picsum.photos/500/300?image=0"
        },
        title: {
            type: String,
            required: true,
            default: "No Title"
        },
        count: {
            type: Number,
            required: false,
            default: -1
        }
    },

    data: () => ({
        loading: false,
        width: 0
    }),

    computed: {
        titleClass() {
            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return "text-h6";
                case "sm":
                    return "text-h6";
                case "md":
                    return "text-h6";
                case "lg":
                    return "text-h6";
                case "xl":
                    return "text-h5";
            }
            return "";
        }
    },

    methods: {
        ...mapActions({
            showMessage: snackbarAction.SNACKBAR_SHOW
        }),

        reserve() {
            this.loading = true;
            setTimeout(() => (this.loading = false), 2000);
        },

        selected() {
            this.$emit('selected')
        }
    },

    mounted() {
        this.$nextTick(function() {
            setTimeout(() => {
                this.width = this.$refs.card.$el.clientWidth - 32
            }, 500);
            //
            //
        });
    }
};
</script>

<style scoped>
.farm-logo {
    height: 100%;
    max-width:100%;
    width: auto;
}

.v-card__title > span {
    word-break: break-word;
}
</style>
